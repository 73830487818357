import { IResponse } from '#types/api';
import {
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  useInfiniteQuery
} from '@tanstack/react-query';
import { useMemo } from 'react';

interface useInfiniteQueryProps<T, U> {
  perPageNum: number;
  queryKeyParam?: (number | string | undefined)[];
  filters: { tag: string; filter?: U } | Record<string, never>;
  queryKeyBase: string;
  queryFn: (context: QueryFunctionContext) => Promise<IResponse<T>>;
  options?: UseInfiniteQueryOptions<IResponse<T>>;
}

export default function useInfiniteScrollQuery<T, U = undefined>({
  perPageNum,
  queryKeyParam = [],
  filters = {}, // 추가적인 필터 파라미터가 있다면 여기에 포함
  queryKeyBase, // 기본 쿼리 키, 필요에 따라 변경 가능
  queryFn,
  options = {}
}: useInfiniteQueryProps<T, U>) {
  const queryKey = useMemo(() => {
    const validQueryKeyParamns = queryKeyParam.filter(value => !!value);
    return [
      queryKeyBase, // 기본 키
      'list', // 'list'를 추가하여 '목록'임을 나타냄
      filters, // 필터 객체 추가,
      ...validQueryKeyParamns
    ];
  }, [queryKeyBase, queryKeyParam, filters]);

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
    remove
  } = useInfiniteQuery<IResponse<T>>(queryKey, queryFn, {
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length + 1; // 모든 페이지의 길이를 기준으로 다음 페이지 계산
      return lastPage.data.length >= perPageNum ? nextPage : undefined; // 마지막 페이지의 데이터 길이가 perPageNum보다 크거나 같으면 다음 페이지 번호 반환
    },
    retry: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options
  });
  const DATA = data?.pages.flatMap(page => page.data);
  const TOTAL = data?.pages[0].total;

  return {
    data: DATA,
    total: TOTAL,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    remove
  };
}
