import { Text } from '#atoms';

interface FieldBoxProps {
  field?: string;
}

export const ExpertFieldBox = ({ field }: FieldBoxProps) => {
  return (
    <Text
      textColor="gray"
      textStyle="c2"
      style={{ lineHeight: '100%', marginBottom: '0.4rem' }}
    >
      {field}
    </Text>
  );
};
